<template>
    <section class="content">
    <div>test <button v-on:click="showDialogAlert">alert</button>
<button v-on:click="showDialogAlert2">alert 2</button>
<button>Browse</button>
<button v-on:click="takePicture">Camera</button>

{{ imgUrl }}
    </div>
    </section>
</template>
<script>
import { Plugins, CameraResultType } from '@capacitor/core';

import { defineCustomElements } from '@ionic/pwa-elements/loader'; 
const { Camera } = Plugins;
export default {
    name: 'Home',
    data() {
        return {
            imgUrl: 'noimg'
        };
    },
    created: function()
    {
	    defineCustomElements(window);
	},
    methods: {
	    async showDialogAlert(){
	      await Plugins.Modals.alert({
	          title: 'Alert',
	          message: 'This is an example alert box'
	      });
	    },
	    showDialogAlert2: async() => {
	      await Plugins.Modals.alert({
	          title: 'Alert2',
	          message: 'This is an example alert box2'
	      });
	    },
	    async takePicture(){
		  const image = await Camera.getPhoto({
		      quality: 90,
		      allowEditing: false,
		      resultType: CameraResultType.Uri
		    });
		    var imageUrl = image.webPath;
		  this.imgUrl = imageUrl;
		  // Can be set to the src of an image now
		  //imageElement.src = imageUrl;
		}
	},
    mounted() {
        
    }
}
</script>
<style type="text/css" scoped>
    

</style>
